@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,800|Raleway:400,600,700");

body {
    font-family: 'Montserrat', sans-serif;
    background-color: #fff;
    color: #000;
    min-height: 100vh;
}

.brand-wrapper {
    padding-top: 7px;
    padding-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
}

.brand-wrapper .logo-large {
    background-image:url(../images/logo-large.png);
    background-size:100%;
    height:94px;
    margin-left:-8px;
    width:260px;
}

.login-section-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    padding: 68px 0px 0px 0px;
    background-color: #fff;
}

.login-wrapper {
    width: 348px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 24px;
    padding-bottom: 24px;
}

.login-wrapper .error-container {
    width: 348px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    background: #f8d7da;
    border: 1px solid #f5c9cd;
    border-radius: 0px;
    margin-bottom: 16px;
}

@media (max-width: 575px) {
    .login-wrapper {
        width: 100%;
    }

    login-wrapper .error-container{
        width: 100%;
    }
}

.login-wrapper .error-container .error-msg {
    color: rgb(122, 40, 46);
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    padding: 15px;
}

.login-wrapper label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(0,0,0);
}

.login-wrapper .form-control {
    height: 40px;
    background: #fff;
    border: 1px solid #d1d2d4;
    border-radius: 0px;
    padding: 9px 5px;
    min-height: 40px;
    font-size: 14px;
    font-weight: normal;
    color: #000;
}

/*.login-wrapper .form-control::-webkit-input-placeholder {*/
/*    color: #b0adad;*/
/*}*/

/*.login-wrapper .form-control::-moz-placeholder {*/
/*    color: #b0adad;*/
/*}*/

/*.login-wrapper .form-control:-ms-input-placeholder {*/
/*    color: #b0adad;*/
/*}*/

/*.login-wrapper .form-control::-ms-input-placeholder {*/
/*    color: #b0adad;*/
/*}*/

/*.login-wrapper .form-control::placeholder {*/
/*    color: #b0adad;*/
/*}*/

.login-wrapper .login-btn {
    background: #d71921;
    border-radius: 0px;
    height: 40px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}

.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
}

.login-wrapper .login-btn:hover{
    background: #ac141a;
}
.login-wrapper .login-btn:active
{
    background: #961117;
}

.login-wrapper .reset-password-link{
    margin-top: 24px;
    margin-bottom: 32px;
    text-align: center;
}

.login-wrapper .reset-password-link a{
    color: #d71921;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    height: 19px;
    letter-spacing: 0px;
}


.login-img {
    width: 100%;
    height: 100vh;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: left;
    object-position: left;
}

.footer-link {
    position: absolute;
    bottom: 1rem;
    text-align: center;
    width: 100%;
}

.no-gutter {
    padding-right: 0;
    padding-left: 0;
}

/* enable absolute positioning */
.inner-addon {
    position: relative;
}

/* style icon */
.inner-addon .input-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 8px;
    pointer-events: none;
}

/* align icon */
.left-addon .input-icon  { left:  0px;}
.right-addon .input-icon { right: 0px;}

/* add padding  */
.left-addon input  { padding-left:  38px !important; }
.right-addon input { padding-right: 38px !important; }

.input-icon-username {
    background-image: url(../images/login-icon-username-email.png);
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
}

.input-icon-password {
    background-image: url(../images/login-icon-password-key.png);
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center center;
}

hr{
    border: 1px solid #d1d2d4;
    margin-top:0px;
    margin-bottom: 0px;
}

.reg-wrapper {
    margin-top: 32px;
}

.reg-wrapper p.text {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;
}

.reg-wrapper .reg-btn {
    color: #d71921;
    font-size: 16px;
    height: 40px;
    width: auto;
    font-weight: bold;
    border: 2px solid #d71921;
    border-radius: 0px;
}

.reg-wrapper .reg-btn:hover {
    border: 2px solid #ac141a;
    color: #ac141a;
}

.reg-wrapper .reg-btn:hover {
    border: 2px solid #8d1016;
    color: #8d1016;
}

.reg-wrapper .reg-link {
    margin-top: 24px;
    margin-bottom: 32px;
    text-align: center;
}

.reg-wrapper .reg-link a{
    color: #d71921;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    height: 19px;
    letter-spacing: 0px;
}

.left-side{
    margin: 0px;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

footer{
    margin-top: auto;
    min-height: 18px;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
}

footer p.help-text{
    color: rgb(64, 64, 64);
}

footer p.help-text a{
    color: rgb(215, 25, 33);
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    height: 18px;
    letter-spacing: 0px;
}

footer .help-info{
    position: relative;
    color: #000;
}
footer .help-info:hover{
    color:#d71921;
    cursor: pointer;
}

footer .row.footer-row{
    margin-top: -35px;
}

footer .row.footer-row p{
    margin: 0px;
}

span.help-info-tooltip-container {
    display: none;
    position: absolute;
    top: -145px;
    z-index: 1000;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    height: 140px;
    width: 431px;
    box-shadow: 0px 0px 3px #333;
}

span.help-info-tooltip-container:after,
span.help-info-tooltip-container:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

span.help-info-tooltip-container:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 5px;
    margin-left: -5px;
}
span.help-info-tooltip-container:before {
    border-color: rgba(51, 51, 51, 0);
    border-top-color: #999;
    border-width: 6px;
    margin-left: -6px;
}

span.help-info-tooltip-container__desktop {
    left: -121px;
}

span.help-info-tooltip-container__mobile {
    left: 15px;
}

span.help-info-tooltip {
    display: block;
    height: 108px;
    width: 399px;
    position: relative;
    background: #d1ecf1;
    border: 1px solid #bee5eb;
    border-radius: 0px;
    margin: 16px;
    padding: 10px;
    color: #0d5460;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

span.help-info-tooltip a {
    font-weight: 900;
    text-decoration: none;
    color: #0d5460;
}

.mt-25{
    margin-top: 25px;
}